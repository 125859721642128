import { products } from "@/data/products";
import { Product } from "./Product";
export const ProductsSection = () => {
  console.log(products);
  return (
    <section id="productos" className="flex flex-col items-center">
      <div className="topbar h-12 flex justify-center items-center pb-[30px] lg:pb-[80px]">
        <h2 className="section-title text-2xl md:text-3xl lg:text-4xl">
          Nuestros productos
        </h2>
      </div>

      {/* 
      .product-list {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    }
      */}
      <div className="relative grid px-3 py-4 gap-8 grid-cols-1 md:grid-cols-2 max-w-screen-xl ">
        {products.map((product) => {
          console.log(product);
          return <Product key={product.id} {...product} />;
        })}
      </div>
    </section>
  );
};
