export const products = [
  {
    description:
      "Utiliza las 54 figuras de la lotería mexicana tradicional en tus tablas!.",
    id: 0,
    image: "/ejemplo4x4.webp",
    name: "Lotería mexicana",
    price: "12.50",
    quantity: "10",
    type: "tablas",
    currency: "MXN",
    disabled: false,
  },
  {
    description: "Utiliza las 54 figuras con tematica navideña en tus tablas!.",
    id: 1,
    image: "/ejemplo5x5.webp",
    name: "Lotería navidad",
    price: "15",
    quantity: "10",
    type: "tablas",
    currency: "MXN",
    disabled: true,
  },
  {
    description:
      "Tablas con 54 figuras con tematica para celebrar un baby shower!.",
    id: 2,
    image: "/ejemplo5x5.webp",
    name: "Lotería baby shower",
    price: "15",
    quantity: "10",
    type: "tablas",
    currency: "MXN",
    disabled: true,
  },
];
